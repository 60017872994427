import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions, selectProgress } from '../../../reducers/importing';
import { selectUserCompany } from '../../../reducers/auth';
import { connect } from 'react-redux';
import { Input, InputLabel, MenuItem, Tooltip } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { createStructuredSelector } from 'reselect';
import { blue } from '@material-ui/core/colors';
import './AddImportModal.css';
import { CollectionsBookmarkOutlined } from '@material-ui/icons';

const validationSchema = () => {
    return (
        Yup.object({
            fileName: Yup.string().required().matches(/.+(\.csv)$/)
        })
    )
}

const styles = theme => ({
    btn: {
        marginTop: '30px'
    },
    loading: {
        margin: '10px 0',
        padding: '10px',
        borderRadius: '3px',
        backgroundColor: '#E7DFDB'
    },
    error: {
        marginTop: 5,
        display: 'inline-block'
    }

});

export function importTypes(locationTreeType, fullList) {
    const importTypes = [];

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));

    const isNetworkDiscovery = fullList || (user && (user.permissions.includes('ADD_NETWORK_DISCOVERY') || user.permissions.includes('EDIT_NETWORK_DISCOVERY')));

    const isLocation = fullList || (user && (user.permissions.includes('ADD_LOCATIONS') || user.permissions.includes('EDIT_LOCATIONS')));
    
    const isDeviceUsers = fullList || (user && (user.permissions.includes('ADD_DEVICE_USERS') || user.permissions.includes('EDIT_DEVICE_USERS')));

    const isPidfloDevices = fullList || (user && (user.permissions.includes('ADD_PIDFLO_DEVICES') || user.permissions.includes('EDIT_PIDFLO_DEVICES')));

    if (isLocation && locationTreeType !== 'SIMPLE') {
        importTypes.push(
            {
                key: 'BUILDING',
                value: 'Buildings'
            }
        )  
    }
    if (isNetworkDiscovery) {
        importTypes.push(
            {
                key: 'BSSID',
                value: 'BSSID'
            },
            {
                key: 'PUBLIC_IP',
                value: 'Public IP Range'
            },
            {
                key: 'MAC_ADDRESS',
                value: 'MAC Address'
            },
            {
                key: 'IP_RANGE',
                value: 'Private IP Range'
            },
            {
                key: 'LLDP_CHASSIS',
                value: 'LLDP Chassis'
            },
            {
                key: 'LLDP_PORT',
                value: 'LLDP Port'
            }
        )
    }
    if (isLocation) {
        importTypes.push(
            {
                key: 'LOCATION',
                value: 'Locations'
            }
        )
    }
    if (isDeviceUsers) {
        importTypes.push(
            {
                key: 'DEVICE_USER',
                value: 'Device User'
            }
        )
    }
    if (isPidfloDevices) {
        importTypes.push(
            {
                key: 'PIDF_LO',
                value: 'PIDF-LO Device'
            }
        )
    }

    return importTypes;
}


export function deleteTypes(locationTreeType, fullList) {
    const deleteTypes = [];

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));

    const isNetworkDiscovery = fullList || (user && (user.permissions.includes('ADD_NETWORK_DISCOVERY') || user.permissions.includes('EDIT_NETWORK_DISCOVERY')));

    const isLocation = fullList || (user && (user.permissions.includes('ADD_LOCATIONS') || user.permissions.includes('EDIT_LOCATIONS')));
    
    const isDeviceUsers = fullList || (user && (user.permissions.includes('ADD_DEVICE_USERS') || user.permissions.includes('EDIT_DEVICE_USERS')));

    if (isLocation && locationTreeType !== 'SIMPLE') {
        deleteTypes.push(
            {
                key: 'BUILDING',
                value: 'Buildings'
            }
        )
    }
    if (isNetworkDiscovery) {
        deleteTypes.push(
            {
                key: 'BSSID',
                value: 'BSSID'
            },
            {
                key: 'MAC_ADDRESS',
                value: 'MAC Address'
            },
            {
                key: 'IP_RANGE',
                value: 'Private IP Range'
            },
            {
                key: 'LLDP_CHASSIS',
                value: 'LLDP Chassis'
            },
            {
                key: 'LLDP_PORT',
                value: 'LLDP Port'
            }
        )
    }
    if (isLocation) {
        deleteTypes.push(
            {
                key: 'LOCATION',
                value: 'Locations'
            }
        )
    }
    if (isDeviceUsers) {
        deleteTypes.push(
            {
                key: 'DEVICE_USER',
                value: 'Device User'
            }
        )
    }

    return deleteTypes;
}

const AddImportForm = props => {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        setFieldValue,
        setFieldTouched,
        modalErrorMessage,
        modalData,
        data,
        validateForm,
        company,
        classes } = props;

    const [submitView, setSubmitView] = useState(false);

    function handleFiles(e) {
        if (e.target.files.length > 0) {
            setFieldValue('fileName', e.target.files[0].name)
            setFieldValue('file', e.target.files[0]);
        }
    }

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    function handleExposeSubmitView() {
        setSubmitView(true);
    }

    useEffect(() => {
        if (values.fileName.length > 0) {
            validateForm();
        }
    }, [values.fileName]);

    return (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading &&
                <div className ={classes.loading} >
                    <LinearProgress variant="determinate" value={props.progress}/>
                    <div className={classes.inputLine}>Upload may take up to a minute or two depending on file size. Please do not close or exit screen</div>
                    <p>Progress: {props.progress}%</p>
                </div>
            }
            <br/>
            {(!submitView || !values.isDelete) &&
                <div>
                    <InputLabel shrink={true}>{values.isDelete ? 'Template Type' : 'Import Types'}</InputLabel>
                    <Select
                        id="importType"
                        name="importType"
                        value={values.importType}
                        onChange={handleChange}
                        disabled={modalLoading}
                        input={<Input name="importType" id="import-type-label"/>}
                        fullWidth={true}
                        >
                        {props.values.isDelete ? deleteTypes(company.toJS().locationTreeType).sort((a, b) => a.value > b.value ? 1 : -1).map( (item,index) => (
                                <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                        )) 
                        : importTypes(company.toJS().locationTreeType).sort((a, b) => a.value > b.value ? 1 : -1).map( (item,index) => (
                                <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                        ))}
                    </Select>
                    <br/>
                    <br/>
                    <div className="upload-wrapper">
                        <div className="upload-btn-wrapper">
                            <label className="upload-button" tabIndex={0} 
                                onKeyDown={e => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        const fileUploadElm = document.querySelector('input[type=file]');

                                        if (fileUploadElm) {
                                            fileUploadElm.click();
                                        }
                                    }
                                }}
                            >
                                Upload CSV
                                <input disabled={modalLoading} type="file" id="input" onChange={handleFiles} 
                                />
                            </label>

                        </div>
                        <br/>
                        <div>{values.fileName ? values.fileName : 'No file provided'}</div>
                    </div>
                    { (errors.fileName && touched.fileName) && <div id="csv-error-message" className={`error-prompt ${classes.error}`}>File type must be CSV</div>}
                    {values.isDelete &&
                        <div>
                            <br/>
                            This operation will delete all the data elements provided in the CSV file. 
                             Please ensure the proper template or export file has been used to indicate which elements are to be deleted. 
                            Note that not all columns are required for the delete operation.
                        </div>
                    }
                </div>
            }
            {(!submitView && values.isDelete) &&
                <Grid container direction="column" justify="center" alignItems="center">
                    <Button id="saveBtn"
                            type="button"
                            className = {classes.btn}
                            disabled={values.fileName.length === 0}
                            onClick={handleExposeSubmitView}
                            color="primary" 
                            variant="contained">Start Bulk Delete</Button>
                </Grid>
            }
            {values.isDelete && submitView &&
                <div>Are you sure you want to delete all items ({values.importType}) included in the CSV file ({values.fileName})?</div>
            }
            {(submitView || !values.isDelete) &&
                <Grid container direction="column" justify="center" alignItems="center">
                    <Button id="saveBtn"
                            type="submit" 
                        disabled={modalLoading} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                            variant="contained">{values.isDelete ? 'Yes' : 'Save'}</Button>
                </Grid>
            }
        </form>
    );
}

const AddImportModal = props => {
    const { modalLoading, classes, modalErrorMessage, company } = props;

	return(
        <Formik
            initialValues={{ 
                importType:  importTypes(company.toJS().locationTreeType)[0].key,
                file: null,
                fileName: '',
                isDelete: props.modalData.modalType === 'delete'
            }}
            validationSchema={validationSchema}
            onSubmit = {
                (values) => {
                    props.submitAddImportForm(values, props.modalData);
                }
            }
			render={formikProps => <AddImportForm
										classes={classes} 
                                        modalLoading={modalLoading}
                                        modalErrorMessage={modalErrorMessage}
                                        progress={props.uploadProgress}
                                        company={company}
										{...formikProps} />}
        />
    );
}

const mapStateToProps = ()  => createStructuredSelector({
    uploadProgress: selectProgress(),
    company: selectUserCompany(),
});

const mapDispatchToProps = dispatch => {
    return {
        submitAddImportForm: (importData, sort) => dispatch(actions.addImportRequest(importData, sort))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddImportModal);